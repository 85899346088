document.addEventListener("DOMContentLoaded", function () {
  // Get the HTML element with the specified class
  var element = document.querySelector(".variations_form.cart");

  if (element) {
    // Access the value of the "data-product_variations" attribute
    var variationsData = element.getAttribute("data-product_variations");

    // Parse the attribute value as an array
    var variationsArray = JSON.parse(variationsData);

    // Select all elements with the class "variable-item"
    var elements = document.querySelectorAll(".variable-item");

    // Get the HTML element with the specified class
    var eanElement = document.querySelectorAll(".pdp-right__ean");

    // Initialize an array to store the selected values
    var selectedValues = [];

    var selectedItems = {};

    // Add the event listener to the .variable-item elements
    var elements = document.querySelectorAll(".variable-item");
    elements.forEach(function (element) {
      element.addEventListener("click", handleClick);
    });
  } else {
    // console.log("Element with class 'variations_form cart' not found.");
  }

  function handleClick(event) {
    // Get the clicked element
    var clickedElement = event.target.closest(".variable-item");

    // Get the value of the clicked element
    var value = clickedElement.getAttribute("data-value");

    // Get the parent ul element
    var parentUl = clickedElement.closest(".variable-items-wrapper");

    // Get the attribute name from the parent ul element
    var attributeName = parentUl.getAttribute("data-attribute_name");

    // Locate the input field with the id "input_2_20"
    var totalPrice = document.getElementById("input_2_20");

    // Update the selectedItems object
    selectedItems[attributeName] = value;

    // Get all elements with the "data-variation-id" attribute
    var elementsWithVariationId = document.querySelectorAll(
      "[data-variation-id]"
    );

    // Iterate over the variationsArray
    for (var i = 0; i < variationsArray.length; i++) {
      var variation = variationsArray[i];

      // Check if the selectedItems match the variation's attributes
      var isMatch =
        Object.keys(selectedItems).length ===
          Object.keys(variation.attributes).length &&
        Object.keys(selectedItems).every(function (key) {
          return selectedItems[key] === variation.attributes[key];
        });

      if (isMatch) {
        // Log matching variation
        console.log(variation);
        console.log("Variation ID: " + variation.variation_id);

        // Update the ean with the variation's variation_ean
        var ean = variation.variation_ean;

        console.log("Checking for EAN element: " + eanElement);
        console.log("Checking for EAN number: " + ean);

        if (eanElement && ean) {
          eanElement.forEach((eanElement) => {
            eanElement.textContent = "EAN: " + ean;
          });
          totalPrice.value = variation.display_price;
        } else {
          eanElement.textContent = "EAN is niet gevonden of ingevuld";
          totalPrice.value = "Prijs is niet gevonden of ingevuld";
        }

        // Select all elements with the class .price-from
        var price_elements = document.querySelectorAll(".price-from");

        // Loop through the selected elements and adjust the price
        price_elements.forEach(function (price_element) {
          price_element.textContent = "€ " + variation.display_price;
        });

        break;
      }
    }
  }
});
/* -------------------------------------------------------------------------- */
/*                           Intersecting PDP Button                          */
/* -------------------------------------------------------------------------- */
function checkIntersection() {
  var pdpAnchor = document.querySelector("#pdp-anchor");
  var offerteAanvragen = document.querySelector("#offerte-aanvragen");
  var siteFooter = document.querySelector(".site-footer");

  var isIntersectingOfferte = false;
  var isIntersectingFooter = false;

  if (pdpAnchor && offerteAanvragen && siteFooter) {
    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.target === offerteAanvragen) {
          isIntersectingOfferte = entry.isIntersecting;
        } else if (entry.target === siteFooter) {
          isIntersectingFooter = entry.isIntersecting;
        }

        if (isIntersectingOfferte || isIntersectingFooter) {
          pdpAnchor.classList.add("hidden");
        } else {
          pdpAnchor.classList.remove("hidden");
        }
      });
    });

    observer.observe(offerteAanvragen);
    observer.observe(siteFooter);
  }
}

checkIntersection();
